@import url('https://fonts.googleapis.com/css2?family=Jost:wght@700&display=swap');

:root {
  --card-color: #2f60ad;
  --junior-color: #AD2F57;
}

@keyframes PopIn {
  from {
    transform: scale(0.8);
  }

  40% {
    transform: scale(1.1);
  }
}

@keyframes PopOut {
  from {
    transform: scale(1.1);
  }

  40% {
    transform: scale(0.8);
  }
}

@keyframes FlipOut {
  0% {
    transform: rotateX(-90deg);
  }
  100% {
    transform: rotateX(0);
  }
}

@keyframes Bounce {
  0%, 20% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(5px);
  }
  60% {
    transform: translateY(-15px);
  }
  80% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0);
  }
}

body {
  font-family: Futura, Jost, sans-serif;
}

button {
  border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  background: none;
}

.board {
  width: 414px;
  margin: 0 auto;
}

.board--solved {
  pointer-events: none;
}

.target {
  font-size: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--card-color);
  color: white;
  margin: 12px auto;
  font-weight: 800;
  width: 160px;
  height: 75px;
  letter-spacing: .1em
}

.target--solved {
  background-color: #6aaa64;
  animation-name: Bounce;
  animation-duration: 1000ms;
}

.source-cards {
  display: flex;
  justify-content: space-between;
  margin: 6px 0;
  padding: 0 12px;
}

.number-card {
  width: 60px;
  height: 60px;
  background: var(--card-color);
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  font-size: 32px;
  box-sizing: border-box;
  animation-duration: 250ms;
}
.number-card.number-card--3-chars {
  font-size: 24px;
}

.number-card.number-card--4-chars {
  font-size: 18px;
}

.number-card.number-card--5-chars {
  font-size: 12px;
}

.number-card--operator {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  font-size: 28px;
  font-family: Futura, Arial, sans-serif; /* jost looks bad */
}

.number-card--empty {
  border: 2px solid #d3d6da;
  background: none;
  cursor: default;
}
.number-card--locked {
  background-color: #787c7e;
  cursor: default;
}
.number-card--problem {
  background-color: #fceeee;
  border: 2px solid #f53a3a;
  color: #f53a3a;
  cursor: default;
}
.number-card--solved {
  animation-name: Bounce;
  animation-duration: 1000ms;
  background-color: #6aaa64;
}
.number-card.number-card--anim-flipout {
  animation-name: FlipOut;
  animation-duration: 500ms;
}
.number-card.number-card--anim-popin {
  animation-name: PopIn;
  animation-duration: 250ms;
}
.number-card.number-card--anim-popout {
  animation-name: PopOut;
  animation-duration: 250ms;
}

.operators {
  display: flex;
  justify-content: space-around;
  margin: 20px 0; 
}

.operation {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 6px 0;
}

.equals-sign {
  font-size: 32px;
  color: #d3d6da;
}

path {
  stroke: red;
}
.icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.icon-button.icon-button--hidden {
  opacity: 0.3;
  pointer-events: none;
}

.app-header {
  text-align: center;
  border-bottom: 2px solid #cdcdcd;
  color: #343434;
  position: relative;
}

.app-header__h1 {
  margin: 0;
  font-size: 32px;
  line-height: 48px;
}
.app-header__help {
  position: absolute;
  left: 12px;
  top: 9px;
  height: 30px;
  width: 30px;
  box-sizing: border-box;


  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: white;
  border: 2px solid #343434;
  color: #343434;
  border-radius: 100%;
  font-weight: 800;
  font-size: 16px;
}

.app-header__timer {
  position: absolute;
  right: 12px;
  top: 9px;
  font-size: 24px;
}

@media (max-width: 413px) {
  .board {
    width: 100%;
  }

  .target {
    width: 38vw;
    height: 18vw;
    font-size: 11.5vw;
  }

  .number-card {
    width: 14vw;
    height: 14vw;
    font-size: 7.5vw;
    border-width: 0.6vw;
  }

  .number-card--operator {
    width: 9.5vw;
    height: 9.5vw;
    font-size: 6.2vw;
  }

  .number-card.number-card--3-chars {
    font-size: 6vw;
  }
  
  .number-card.number-card--4-chars {
    font-size: 4vw;
  }
  
  .number-card.number-card--5-chars {
    font-size: 3vw;
  }

  .icon-button {
    transform: scale(0.9);
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content {
  border-radius: 12px;
  margin: 40px auto;
  width: 500px;
  background: white;
  padding: 16px 32px;
  box-sizing: border-box;
}

.modal__h2 {
  margin: 0;
}

.modal__demo-container {
  height: 470px;
  position: relative;
}

.modal__demo {
  pointer-events: none;
  transform-origin: top center;
  transform: translateX(-50%) scale(0.9);
  position: absolute;
  left: 50%;
}

@media(max-width: 540px) {
  .ReactModal__Overlay {
    padding: 0 20px;
  }

  .ReactModal__Content {
    width: auto;
  }
}

.share-button-container {
  text-align: center;
  margin: 20px 0;
}

.share-button {
  background-color: #6aaa64;
  color: white;
  font-size:24px;
  padding: 10px 30px;
}